import { type Invoice } from '~/models/Invoice';

export type InvoiceState = {
    invoice: Invoice | null;
}

export const defaultStateData = (): InvoiceState => ({
    invoice: null,
});

export const DefaultState = (): InvoiceState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
