import { SET_INVOICE } from './-mutation-types';
import { useInvoicesStore } from '~/stores/invoices';
import { type Invoice } from '~/models/Invoice';

const Mutations = {
    [SET_INVOICE](payload: Invoice | null) {
        const invoicesStore = useInvoicesStore();
        invoicesStore.invoice = payload;
    },
};

export default Mutations;
