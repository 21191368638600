import { GET_INVOICE } from './-getter-types';
import { useInvoicesStore } from '~/stores/invoices';
import { type Invoice } from '~/models/Invoice';

const Getters = {
    [GET_INVOICE]: (): Invoice | null => {
        const invoicesStore = useInvoicesStore();

        return invoicesStore.invoice;
    },
};

export default Getters;
