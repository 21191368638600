import { SET_INVOICE } from './-mutation-types';
import { useInvoicesStore } from '~/stores/invoices';
import InvoiceService from '~/services/api/crm/InvoiceService';
import { type Invoice } from '~/models/Invoice';

const Actions = {
    async fetchInvoice(invoiceId: number) {
        const invoicesStore = useInvoicesStore();
        const invoiceService = new InvoiceService();
        const response = await invoiceService.fetchInvoice(invoiceId);

        invoicesStore[SET_INVOICE](response);
    },
    setInvoice(invoice: Invoice) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_INVOICE](invoice);
    },
};

export default Actions;
